<template>
  <div class="temporary" > 
    <a-spin :spinning="spinnings" :tip="message"> 
        <div class="d-flex flex-column min-vh-100" >
        </div>
  </a-spin>
  </div>
</template>
<script>
import router from "../router";
import { mixinWebsocket } from "../wsocket/wsobj.js";
import { mapMutations } from "vuex";
export default {
  name: 'Temporary',
  mixins: [mixinWebsocket],  
  data() {
    return {
      message:'資料載中...',
      spinnings:true,
    };
  },  
  mounted(){
    this.websocketclose();
    this.myRedirect(this.$route.params.id);
  },
  methods: {
    ...mapMutations("ws", ["setWsState"]), 
    myRedirect(targetPage){
        router.replace({ name: targetPage }).catch(err => {err;});
    },
  },     
}
</script>
<style scoped>

</style>

